import { SVGAttributes } from "react"

export const Import = ({
  height = 18,
  width = 18,
  fill,
}: SVGAttributes<Element>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2605 22.25H8.74047C3.83047 22.25 1.73047 20.15 1.73047 15.24V15.11C1.73047 10.67 3.48047 8.53003 7.40047 8.16003C7.80047 8.13003 8.18047 8.43003 8.22047 8.84003C8.26047 9.25003 7.96047 9.62003 7.54047 9.66003C4.40047 9.95003 3.23047 11.43 3.23047 15.12V15.25C3.23047 19.32 4.67047 20.76 8.74047 20.76H15.2605C19.3305 20.76 20.7705 19.32 20.7705 15.25V15.12C20.7705 11.41 19.5805 9.93003 16.3805 9.66003C15.9705 9.62003 15.6605 9.26003 15.7005 8.85003C15.7405 8.44003 16.0905 8.13003 16.5105 8.17003C20.4905 8.51003 22.2705 10.66 22.2705 15.13V15.26C22.2705 20.15 20.1705 22.25 15.2605 22.25Z"
      fill={fill}
    />
    <path
      d="M12 15.63C11.59 15.63 11.25 15.29 11.25 14.88V2C11.25 1.59 11.59 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2V14.88C12.75 15.3 12.41 15.63 12 15.63Z"
      fill={fill}
    />
    <path
      d="M11.9998 16.7501C11.8098 16.7501 11.6198 16.6801 11.4698 16.5301L8.11984 13.1801C7.82984 12.8901 7.82984 12.4101 8.11984 12.1201C8.40984 11.8301 8.88984 11.8301 9.17984 12.1201L11.9998 14.9401L14.8198 12.1201C15.1098 11.8301 15.5898 11.8301 15.8798 12.1201C16.1698 12.4101 16.1698 12.8901 15.8798 13.1801L12.5298 16.5301C12.3798 16.6801 12.1898 16.7501 11.9998 16.7501Z"
      fill={fill}
    />
  </svg>
)
