import { SVGAttributes } from "react"

export const FilterEdit = ({
  width = 16,
  height = 16,
  fill,
}: SVGAttributes<Element>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9308 22.75C10.4508 22.75 9.97082 22.63 9.53082 22.39C8.64082 21.89 8.11078 21 8.11078 19.99V14.64C8.11078 14.13 7.78076 13.38 7.47076 12.99L3.67078 9.00003C3.04078 8.37003 2.55078 7.27002 2.55078 6.46002V4.14005C2.55078 2.53005 3.7708 1.27002 5.3208 1.27002H18.6608C20.1908 1.27002 21.4308 2.51004 21.4308 4.04004V6.26004C21.4308 7.31004 20.8008 8.52002 20.2008 9.11002C19.9108 9.40002 19.4308 9.40002 19.1408 9.11002C18.8508 8.82002 18.8508 8.34002 19.1408 8.05002C19.5108 7.68002 19.9308 6.85004 19.9308 6.26004V4.04004C19.9308 3.34004 19.3608 2.77002 18.6608 2.77002H5.3208C4.6108 2.77002 4.05078 3.37005 4.05078 4.14005V6.46002C4.05078 6.83002 4.35078 7.56004 4.74078 7.95004L8.59082 12C9.10082 12.63 9.60077 13.69 9.60077 14.64V19.99C9.60077 20.65 10.0508 20.97 10.2508 21.08C10.6808 21.32 11.1908 21.31 11.5908 21.07L12.9908 20.17C13.3408 19.95 13.8008 20.05 14.0308 20.4C14.2508 20.75 14.1508 21.21 13.8108 21.44L12.4008 22.35C11.9508 22.61 11.4408 22.75 10.9308 22.75Z"
      fill={fill}
    />
    <path
      d="M13.6907 18.89C13.3107 18.89 12.9507 18.75 12.6907 18.49C12.3807 18.18 12.2407 17.73 12.3107 17.26L12.5007 15.91C12.5507 15.56 12.7607 15.15 13.0107 14.9L16.5506 11.36C17.0306 10.88 17.5007 10.63 18.0107 10.58C18.6307 10.52 19.2507 10.78 19.8307 11.36C20.4107 11.94 20.6706 12.55 20.6106 13.18C20.5606 13.68 20.3007 14.16 19.8307 14.64L16.2906 18.18C16.0406 18.43 15.6207 18.64 15.2707 18.69L13.9206 18.88C13.8406 18.89 13.7707 18.89 13.6907 18.89ZM18.1807 12.06C18.1707 12.06 18.1607 12.06 18.1507 12.06C18.0107 12.07 17.8206 12.19 17.6106 12.41L14.0707 15.95C14.0407 15.98 13.9907 16.08 13.9907 16.12L13.8107 17.37L15.0607 17.19C15.1007 17.18 15.2006 17.13 15.2306 17.1L18.7707 13.56C18.9807 13.35 19.1107 13.16 19.1207 13.02C19.1407 12.82 18.9407 12.58 18.7707 12.41C18.6107 12.25 18.3807 12.06 18.1807 12.06Z"
      fill={fill}
    />
    <path
      d="M18.7897 15.36C18.7197 15.36 18.6498 15.35 18.5898 15.33C17.2698 14.96 16.2197 13.91 15.8497 12.59C15.7397 12.19 15.9697 11.78 16.3697 11.66C16.7697 11.55 17.1797 11.78 17.2897 12.18C17.5197 13 18.1697 13.65 18.9897 13.88C19.3897 13.99 19.6198 14.41 19.5098 14.81C19.4198 15.15 19.1197 15.36 18.7897 15.36Z"
      fill={fill}
    />
  </svg>
)
