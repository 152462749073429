import { createGlobalStyle, withTheme } from "styled-components"

import { Theme } from "./themes"

const getColor = (props: { theme: Theme }) =>
  props.theme.newTheme.color["Colors/Background/bg-tertiary"]

export const GlobalScrollbarStyle = withTheme(createGlobalStyle`
body, #root {
  overflow: hidden;
}

body ::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

body ::-webkit-scrollbar-thumb {
  background-color: ${getColor};
}

body {
  scrollbar-color: ${getColor} transparent;
}

body ::-webkit-scrollbar-thumb {
  border-radius: 22px;
  background-color: rgba(212, 221, 232, .4);
  

  height: 16px;
  border: 4.5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

body ::-webkit-scrollbar-corner {
  background-color: rgba(0,0,0,0);
}

body .ag-body ::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
`)
