import type { ReactiveTraderIcon } from "./types"

export const PopOutIcon = ({
  width = 16,
  height = 16,
  fill,
}: ReactiveTraderIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeWidth={0.25} fill={fill}>
      <path
        d="M8.6663 7.83316C8.53964 7.83316 8.41297 7.7865 8.31297 7.6865C8.11964 7.49316 8.11964 7.17316 8.31297 6.97983L13.7796 1.51316C13.973 1.31983 14.293 1.31983 14.4863 1.51316C14.6796 1.7065 14.6796 2.0265 14.4863 2.21983L9.01964 7.6865C8.91964 7.7865 8.79297 7.83316 8.6663 7.83316Z"
        fill={fill}
      />
      <path
        d="M14.6668 5.0335C14.3935 5.0335 14.1668 4.80683 14.1668 4.5335V1.8335H11.4668C11.1935 1.8335 10.9668 1.60683 10.9668 1.3335C10.9668 1.06016 11.1935 0.833496 11.4668 0.833496H14.6668C14.9401 0.833496 15.1668 1.06016 15.1668 1.3335V4.5335C15.1668 4.80683 14.9401 5.0335 14.6668 5.0335Z"
        fill={fill}
      />
      <path
        d="M9.99967 15.1668H5.99967C2.37967 15.1668 0.833008 13.6202 0.833008 10.0002V6.00016C0.833008 2.38016 2.37967 0.833496 5.99967 0.833496H7.33301C7.60634 0.833496 7.83301 1.06016 7.83301 1.3335C7.83301 1.60683 7.60634 1.8335 7.33301 1.8335H5.99967C2.92634 1.8335 1.83301 2.92683 1.83301 6.00016V10.0002C1.83301 13.0735 2.92634 14.1668 5.99967 14.1668H9.99967C13.073 14.1668 14.1663 13.0735 14.1663 10.0002V8.66683C14.1663 8.3935 14.393 8.16683 14.6663 8.16683C14.9397 8.16683 15.1663 8.3935 15.1663 8.66683V10.0002C15.1663 13.6202 13.6197 15.1668 9.99967 15.1668Z"
        fill={fill}
      />
    </g>
  </svg>
)
